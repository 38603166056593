import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import PlaneIcon from "./images/plane-icon.png";
import { ReactComponent as ScrollDownIcon } from "./images/scroll-down.svg";

class App extends React.Component {

	render() {
		return (
			<div className="app">
				<header className="app-header">
					<Container fluid={true}>
						<Row id="app-header-image-row">
							<Col className="pl-0">
								<img
									id="app-header-image"
									src={PlaneIcon}
									alt="Plane Mover: We Have a New Look!"
									className="img-fluid"
								/>
							</Col>
						</Row>
						<Row id="app-header-scroll-row" className="justify-content-center">
							<Col>
								<a href="#signup-form" title="Scroll Down">
									<ScrollDownIcon />
								</a>
							</Col>
						</Row>
					</Container>
				</header>
				<main className="app-main">
					<Container>
						<Row className="justify-content-center">
							<Col xs={12} lg={10}>
								<Row className="align-items-center">
									<Col xs={12} md={6} className="text-center text-md-right">
										<h1>
											Our New Website Is <br />
											Taking Flight Soon!
										</h1>
									</Col>
									<Col xs={12} md={6} className="text-center text-md-left">
										<ul id="app-main-features-list" className="features">
											<li>Aircraft for Sale Listings</li>
											<li>
												Aircraft Wanted Listings <strong>NEW!</strong>
											</li>
											<li>
												Aircraft Services Listings <strong>NEW!</strong>
											</li>
											<li>
												Keep track of your Favorite Listings <strong>NEW!</strong>
											</li>
										</ul>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</main>
				<footer className="app-footer">
					<Container>
						<Row className="justify-content-center">
							<Col xs={12} md={10} lg={8} className="text-center">
								<div className="ctct-inline-form" data-form-id="4beeb036-a8e9-4411-b447-752ae86c1f15"></div>
								<a href="http://planemover.com" title="Plane Mover">
									No, thank you. Take me to PlaneMover.com
								</a>
							</Col>
						</Row>
					</Container>
				</footer>
			</div>
		);
	}
}

export default App;
